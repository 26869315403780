<script setup>
import { computed } from 'vue';;
import { useLayout } from '@/layout/composables/layout';


const { layoutConfig, layoutState } = useLayout();
const containerClass = computed(() => {
    return {
        'layout-theme-light': layoutConfig.darkTheme.value === 'light',
        'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-input-filled': layoutConfig.inputStyle.value === 'filled',
        'p-ripple-disabled': !layoutConfig.ripple.value
    };
});

</script>

<template>
    <div class="layout-wrapper layout-wrapper-mod" :class="containerClass">
            <div class="layout-main">
                <router-view></router-view>
            </div>
        <div class="layout-mask"></div>
    </div>
</template>

<style lang="scss" scoped>
</style>
