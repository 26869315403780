import { createRouter, createWebHashHistory } from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue';


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/auth'
    },
    {
      path: '/dashboard',
      component: AppLayout,
      children: [
        {
            path: '/auth',
            name: 'auth',
            component: () => import('@/views/PatientAuth.vue'),
            meta: { title: 'Patient - Insight' }
        },
        {
            path: '/service-selection',
            name: 'serviceSelection',
            component: () => import('@/views/ServiceSelection.vue'),
            meta: { title: 'Service Selection - Insight' }
        },
        {
            path: '/vital-capture',
            name: 'vitalCapture',
            component: () => import('@/views/VitalCapture.vue'),
            meta: { title: 'Vitals Capture - Insight' }
        },
        {
            path: '/physical-therapy',
            name: 'physicalTherapy',
            component: () => import('@/views/PhysicalTherapy.vue'),
            meta: { title: 'Physical Therapy - Insight' },
        },
        {
            path: '/mental-therapy',
            name: 'mentalTherapy',
            component: () => import('@/views/MentalTherapy.vue'),
            meta: { title: 'Mental Therapy - Insight' },
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notfound',
      component: () => import('@/views/pages/NotFound.vue')
    },
  ]
})

// Navigation Guard
router.afterEach(async (to, from) => {
    // Force reload if you took measurement and want to take another measurement.
    if(to.name === 'serviceSelection' && from.name === 'vitalCapture') {
        window.location.reload();
    };
    // Navigate directly if going through additional care plan step
    if(to.name === 'physicalTherapy' && from.name === 'vitalCapture') {
        localStorage.setItem('additionalPhysicalExercise', "True");
    }
    if(to.name === 'mentalTherapy' && from.name === 'vitalCapture') {
        localStorage.setItem('additionalMentalExercise', "True");
    }
})

export default router
